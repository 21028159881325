<template>
  <div>
    <q-chip color="primary" text-color="white" square>
      {{ codeTableTemp.documentStatusMapping[module][value] }}
    </q-chip>
  </div>
</template>

<script>
export default {
  name: "ProReportDocumentStatusTdField",
  props: {
    value: null,
    filterOption: {},
    module: {
      type: String,
      required: true,
    },
    codeTableTemp: {
      type: Object,
      required: true,
    },
  },
};
</script>
